
import React,{useRef,useState,useEffect,memo,useCallback} from 'react'
import {useParams} from 'react-router-dom'
import Page from './Page'
import {fetchCMSJSON} from 'system/AssetManager'
import axios from 'axios'
import Globals from 'system/Globals'


const PageRender = function(props){

    let { id,pageid } = useParams();
    
    let [pageModel,setPageModel] = useState(null)

    const [loading , setLoading ]= useState(true)
    const [tempUser, setTempUser] = useState(true);

    useEffect(()=>{

        if (props && props.location.search) {
            let narrativeToken;
        
            let params = new URLSearchParams(props.location.search)
            narrativeToken = params.get('u');
            
            console.log(narrativeToken)
            
            if (narrativeToken) {
             
                axios({
                    method: 'post',
                    baseURL: Globals.instance().adminOriginAPI,
                    url: 'api/core/verify-narrative-token/',
                    data: {
                        "link_token": narrativeToken,
                        "narrative_id": id
                    }
                    })
                    .then(response => {  

                        let userObj = {}
                        userObj.narratives = [parseInt(id)]
                        userObj.market= null
                        userObj.category= "anon"
                        userObj.device_sn = null
                        userObj.link_token = narrativeToken
                        userObj.token = narrativeToken
                        console.log('userObj', userObj)
                        // props.setUser(userObj)
                        setTempUser(userObj)
                       

                    }).catch(function (error) {
                        console.error(error.response)    
                        
                    })
    
            } 
        } 
       
        console.log('Globals', Globals)

    },[props])

    useEffect(() => {
        if (tempUser) {
            const loadPage = async (url)=>{
                try{
                    let pm = await fetchCMSJSON(url,null,null,tempUser.token)
                    setPageModel(pm)
                    setLoading(false)
                }catch( e ){
                
                    console.error(e)
             
                }
            }
            console.log('narrative', id)
           loadPage('narrative/'+id+'/'+pageid+'/?format=json')
        }
    }, [tempUser])

    function setScrollTable(){

    }
    function onPageLoad(){
        
    }
    function Interaction(){}
    function onIntersect(){}

    return (<div className={`fullscreen pagerender ${loading?'loading':'loaded'}`}>
          
          { pageModel && (
          <Page key={`${id}-${pageid}` } 
            id={`${id}-${pageid}` } 
            model={pageModel}  
            onLoad={onPageLoad}
            interaction={Interaction}
            pageIndex={0} 
            theme={{}}
            onIntersect={onIntersect}
            setScrollCallback={setScrollTable}
            renderOnly={true}
            ></Page>)
        }
                    )

        </div>)
    
}

export default PageRender;
