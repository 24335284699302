import React,{useState,useRef,useCallback,useEffect} from 'react'
import Globals from 'system/Globals'
import {ReactComponent as IconTools} from 'assets/icons/tools.svg'
import {ReactComponent as IconClose} from 'assets/icons/close-thin.svg'
import {ReactComponent as IconNav} from 'assets/icons/tool_nav.svg'
import {ReactComponent as IconNote} from 'assets/icons/tool_comment.svg'
import {ReactComponent as IconDraw} from 'assets/icons/tool_telestrator.svg'
import {ReactComponent as IconFullscreen} from 'assets/icons/tool_fullscreen.svg'
import {ReactComponent as IconMinimize} from 'assets/icons/tool_minimize.svg'
import {ReactComponent as IconLink} from 'assets/icons/tool_link.svg'
import gsap,{Power2} from 'gsap'

import styles from './toolsmenu.module.scss'



export default function ToolsMenu(props){

    const [menuOpen,setMenuOpen] = useState(false)
    const refContainer = useRef()
    const [fullscreen,setFullScreen] = useState(Globals.instance().isFullscreen)
    const refOpenTimer = useRef(0)

    useEffect(()=>{
        return ()=>{
            clearTimeout(refOpenTimer.current)
        }
    },[])

    const closeMenu=useCallback(()=>{
        clearTimeout(refOpenTimer.current)
        if(menuOpen===false || refContainer.current === undefined)return
        let subs =Array.from(refContainer.current.querySelectorAll('.'+styles.btnSub))
        
        subs.forEach( (ele,index)=>{
            gsap.to(ele,{opacity:0,duration:0.15,ease:Power2.easeInOut,
                onComplete:(last,container)=>{
                if(last){
                    gsap.to(container,
                        {width:getComputedStyle(container).getPropertyValue('width'),
                        duration:0.2,
                        onUpdate:function(c){
                            // let per =(100-(this.progress()*50))
                            // c.style.transformOrigin='50% '+per+'%'
                        },
                        onUpdateParams:[container],
                        ease:Power2.easeInOut,
                        onComplete:()=>{
                            container.style.removeProperty('transform')
                            container.style.removeProperty('left')
                            container.style.removeProperty('width')
                            subs.forEach(e=>{e.style.display='none'})
                            setMenuOpen(false)
                        }
                    })
                }
            },onCompleteParams:[subs.length === index+1,refContainer.current]})
        })
    },[menuOpen])

    const resetCloseTimer=useCallback(()=>{
        refOpenTimer.current=setTimeout(()=>{
            closeMenu()
        },7000)
    },[closeMenu])

    useEffect(()=>{
        if(menuOpen){
            resetCloseTimer()
        }else{
            clearTimeout(refOpenTimer.current)
        }
    },[menuOpen,resetCloseTimer])

    function onClickToggleTools(){
            
        if(menuOpen){
            //closing
           closeMenu()
        }else{
            //opening 
            let subs =Array.from(refContainer.current.querySelectorAll('.'+styles.btnSub))
            
            let h = getComputedStyle(refContainer.current).getPropertyValue('width')
            subs.forEach( (ele,index)=>{ ele.style.display='flex'})
            refContainer.current.style.width='auto'
            subs.forEach( (ele,index)=>{
                gsap.to(ele,{opacity:1,duration:0.1,ease:Power2.easeInOut})
             })
            
            let h2 = parseFloat(getComputedStyle(refContainer.current).getPropertyValue('width'))
            gsap.fromTo(refContainer.current,{width:h},{width:h2+50,duration:0.2,ease:Power2.easeInOut,onComplete:()=>{}})
           
            setMenuOpen(!menuOpen)      
            gsap.to(refContainer.current,{transformOrigin:"50% 50%", left: 0, duration:0.2,ease:Power2.easeInOut})
        }
       
    }

    function onClickNav(){
        props.setShowControls(true)
        Globals.instance().trackEvent('click-nav',{ 'event_category':'tools'})
        onClickToggleTools()        
    }
    function onClickDraw(){
        console.log("draw")
        Globals.instance().trackEvent('click-draw',{ 'event_category':'tools'})
        props.setShowDrawControls(true)
        onClickToggleTools()        
    }

    function onClickFullscreen(){
        Globals.instance().toggleFullscreen()
        Globals.instance().trackEvent('click-toggle',{ 'event_category':'tools'})
        setFullScreen(!fullscreen)
        onClickToggleTools()    
    }
    
    
    function onClickMarkup(){
        if(typeof props.setShowMarkup === 'function'){
         props.setShowMarkup(true)
        }
         onClickToggleTools() 
    }
    function onClickLink(){
        var search;
        if (window.location.hash !== "") {
            search = window.location.hash;
            let search2 = search.split('?nav=open');
            if (search2[1]) {
                search = search2[1];
            }
            
        } else {
            search = window.location.search;
        }

        let params = new URLSearchParams(search);
        params.set('nav', 'closed')
        let presentationMode = params.get('presentationMode');
        let serverApp = params.get('serverApp');

        if (presentationMode) {
            params.delete('presentationMode')
        }
        if (serverApp) {
            params.delete('serverApp')
        }

        var strLink;
        if (props.location) {
            strLink = Globals.instance().webURL+ props.location.pathname + '?' + params.toString()
        } else {
            strLink = Globals.instance().webURL+'/narrative/'+ props.model.id + '?' + params.toString();
        }
        console.log('strLink', strLink, props.model.heading, params.toString())
        if (navigator.share) {
            // Web Share API is supported
            navigator.share({
                title: 'Brookfield Presentation Share: '+ props.model.heading,
                url: strLink
              }).then(() => {
                console.log('Thanks for sharing!');
              })
          } else {
            // Fallback
                var textArea = document.createElement("textarea");
                textArea.value = strLink;

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    console.log('Copying text command was ' + msg);
                    Globals.instance().showAlert('Link Copied to Clipboard','&nbsp;<small>'+strLink+'</small>&nbsp;')
                } catch (err) {
                    console.log('Oops, unable to copy');
                }
                        document.body.removeChild(textArea);
        }
    }
    return(
    <React.Fragment>
       
        <div className={` ${styles.menuContainer} ${menuOpen?'open':'closed'}`} ref={refContainer} onMouseOut={()=>{
            clearTimeout(refOpenTimer.current)
            refOpenTimer.current=setTimeout(()=>{closeMenu()},5000)
        }} >
        { menuOpen && (
            <React.Fragment>
            <div className={`${styles.btnClose} `}  style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)} onClick={onClickToggleTools} >
            <IconClose></IconClose>
        </div>
            <div className={`${styles.separator} `}></div></React.Fragment>
           
      
        )}
       
                <React.Fragment>
                    { typeof props.setShowControls==="function" && (
                        <div className={`${styles.btnSub}`} onClick={onClickNav} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                            <IconNav></IconNav>
                        </div>
                    )}
                    {!Globals.instance().ios &&(
                    <div className={`${styles.btnSub}`} onClick={onClickFullscreen} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                        {!fullscreen && (<IconFullscreen></IconFullscreen>)}
                        {fullscreen && (<IconMinimize></IconMinimize>)}
                    </div>
                    )}
                    <div className={`${styles.btnSub}`} onClick={onClickLink} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                        <IconLink></IconLink>
                    </div>
                    <div className={`${styles.btnSub}`} onClick={onClickDraw} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                        <IconDraw></IconDraw>
                    </div>
                    <div className={`${styles.btnSub} ${styles.markUpBtn}`} onClick={onClickMarkup} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                        <IconNote></IconNote>
                    </div>
                </React.Fragment>

                {!menuOpen && (
                    <div className={`${styles.btnTools}`}  style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)} onClick={onClickToggleTools} >
                        <IconTools></IconTools>
                    </div>  
                )}
         
            
        </div>
    </React.Fragment>
    )

}