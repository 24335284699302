
import React,{useEffect,useRef} from 'react'
import gsap,{Back} from 'gsap'
import styles from '../styles/alert.module.css'

const Alert =props=>{

    const ref =  useRef()
    const refHolder =  useRef()

    useEffect(()=>{
        gsap.to(ref.current,{opacity:1,duration:0.25})
        gsap.from(refHolder.current,{opacity:0,scale:0.75,duration:3,ease:Back.easeOut})
        console.log('alert',props.alert,props)
    },[])

    function onClickOk(){
        gsap.to(refHolder.current,{opacity:0,scale:0.75,duration:0.25,ease:Back.easeOut})
        gsap.to(ref.current,{opacity:0,duration:0.25,onComplete:()=>{
            window.dispatchEvent(new CustomEvent('close-alert'))
        }})
    }

    function onClickContinue(continueRule){
        gsap.to(refHolder.current,{opacity:0,scale:0.75,duration:0.25,ease:Back.easeOut})
        gsap.to(ref.current,{opacity:0,duration:0.25,onComplete:()=>{
            window.dispatchEvent(new CustomEvent('close-alert'))
        }})
        continueRule()
    }

    return (<div className={`${styles.alertContainer} fullscreen fcenter`} ref={ref} >

            <div className={`${styles.alertHolder}`} ref={refHolder}>
                <div className={`${styles.title}`}>{props.alert.title}</div>
                <div className={`${styles.message}`} dangerouslySetInnerHTML={{ __html: props.alert.message}}></div>
                {(props.alert.cancel && props.alert.continueBtn) ?
                    <span className={`${styles.twoBtns}`}>
                    <div className={`${styles.btn}`} onClick={onClickOk}>Cancel</div>
                    <div className={`${styles.btn}`} onClick={() => onClickContinue(props.alert.continueBtn)}>Continue</div>
                    </span>
                    :
                <div className={`${styles.btn}`} onClick={onClickOk}>OK</div>}
            </div>

    </div>)

}

export default Alert