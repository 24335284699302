
import React,{useState,useRef,useEffect,useLayoutEffect} from 'react'

const ScaleText = React.forwardRef((props,ref)=>{

    const refTxtEle=useRef()
    const resize=useRef(false)
    const [viewBox,setViewBox] = useState(props.viewBox?props.viewBox:"0 0 0 0")
    const [textStyle,setTextStyle] = useState()
    const isMounted = useRef(false)

    useEffect(()=>{
        isMounted.current = true;
        // console.log("setting viewbox")
        if(props.viewBox)
        //     setViewBox(props.viewBox)
        // else{
        //     setViewBox(`0 0 ${ref.current.clientWidth} ${ref.current.clientHeight}`)
        
            resize.current=true
        
        
       if(props.textStyle)
            setTextStyle(props.textStyle)
        else
            setTextStyle({margin:0,whiteSpace:'normal',display:'inline-block'})
        
            return () => { isMounted.current = false ;console.log("clear scale")}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // useLayoutEffect(()=>{
        
    //     if(isMounted.current && resize.current){
    //         // console.log(refTxtEle.current.clientWidth)
    //         setViewBox(`0 0 ${refTxtEle.current.clientWidth} ${refTxtEle.current.clientHeight}`)
    //         resize.current=true
    //     }
    // },[props.containerStyle])

   

    return (
        <div style={props.containerStyle} className={props.className} ref={ref}>
         
        </div>
    )

})
export default ScaleText

                
// <text id="Reimagined_Real_Estate" data-name="Presentation
// System" transform="translate(0 39)" fill="#0f3557" fontSize="38" fontFamily="CerebriSans, Helvetica-Bold, Helvetica" fontWeight="200"><tspan x="0" y="0">Create + Present</tspan></text>
