
import React,{useState,useRef,memo,useEffect,useCallback} from 'react'
import history from 'system/navigation/MainRouterHistory'
import styles from './page.module.css'
import * as DynamicComponentMap from  './canvas/dynamic'
import Globals from '../system/Globals'


const Page = memo(function(props){

    let loadCount = 0
    const refPage= useRef()
    const [inline,setInline] = useState({})
    const [inlineContainer,setInlineContainer] = useState({})
    const [format,setFormat] = useState(null)
    const refScrollTable =useRef({})
    
    const [loaded,setLoaded] = useState(false)
    const refCallbacks=useRef(new Array(props.model.components.length))
    const refInteractionListener = useRef()
    // const refElements=useRef([])
    const refParallaxElements = useRef([])
    const refThrehold = useRef(0.5)
    const refContainerScale = useRef(1)
    const [renderNumber, setRenderNumber] =useState(0)
    
    //initial pageState
    let ps;
    props.model.components.map((val,i)=>{ if(val.component=="statePNGButton" && ps===undefined) ps=val.id })
    const [pageState,setPageState] = useState(ps)

    function setScrollTable(cb,index){
        if(cb) refScrollTable.current[index]=cb
    }

    const handleResize = ()=>{
        let format = Globals.instance().getFormat(props.model.formats)
        let w= format['canvas_width']?parseFloat(format['canvas_width']):window.innerWidth
        let h= format['canvas_height']?parseFloat(format['canvas_height']):'1080'
        if(w==='100%')w=window.innerWidth
        let scale=window.innerHeight/h
        refContainerScale.current=scale
        let outterwidth = 0
        
        if( format.fit==="fill" || format.fit==="crop" || format['canvas_width'] === "100%")
            outterwidth=window.innerWidth
        else 
            outterwidth=scale*w
        
        setInline({ width: Math.floor(outterwidth)+'px' , height:window.innerHeight+'px' })
        let leftOffset=  format.fit==="crop"?(window.innerWidth-(w*scale))/2:0
        if(leftOffset>0)leftOffset=0    
        if( format.fit==="fill" || format['canvas_width'] === "100%" )
        {w=window.innerWidth/scale}
        scale = Math.round(scale*1000)/1000 //round up a hair 
        setInlineContainer({ width: w+'px', height:h+'px', transform:'scale3d('+scale+','+scale+',1)'  , transformOrigin:'top left', left:leftOffset,scale:scale})   
        setRenderNumber(renderNumber+1)
        refThrehold.current = window.innerWidth/outterwidth/2
        if(refThrehold.current>0.5)refThrehold.current=0.5

    }

    const handleScroll =useCallback((scrollLeft,screenWidth)=>{
       
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(refScrollTable.current)) {
           value.call(this,scrollLeft,refContainerScale.current,screenWidth)
        }
        
    },[refScrollTable,refContainerScale])
    
    useEffect(()=>{
            let elePageRef=refPage.current
            //get format
            setFormat(Globals.instance().getFormat(props.model.formats))
            
            handleResize()
            if(props.model.components.length===0){
                console.warn("page with no components added")
                onLoadComplete()
            }

            let onServerEvent=(evt)=>{
                let detail = evt.detail
                if(!detail)return
                if(detail.event==='narrative-page-state'){
                  console.log('PAGE STATE '+detail.command)
                    let state =props.model.controller_states.find(m=>{return m.id===Number(detail.command)})
                    console.log(state)
                    if(state)
                    doChangeState(Number(detail.command))
                } 
            }

            // console.log('props', props.model)

             //listen to server event
             if(Globals.instance().serverApp && props.model.controller_states && props.model.controller_states.length){
                window.removeEventListener('server-event',onServerEvent)
                window.addEventListener('server-event',onServerEvent)
            }

    

            return ()=>{
                window.removeEventListener('resize',handleResize)
                window.removeEventListener('server-event',onServerEvent)
                refCallbacks.current=undefined
                elePageRef.removeEventListener('intersection',refInteractionListener.current)
            }      
            
           
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const doChangeState =  useCallback((s)=>{ 
        console.log('set pstae ',s)
        setPageState(s)},[setPageState])

    function onLoadComplete(){
        
        props.onLoad(props.index,refPage.current,refParallaxElements.current)
        
        refInteractionListener.current = (evt)=>{
            // console.log("ON INTERSECT"+props.model.id +' '+ evt.detail.intersectionRatio)
            if(evt.detail.isIntersecting && evt.detail.intersectionRatio>0.35){
                props.onIntersect(props.model.id)
            }
            refCallbacks.current.forEach(f=>{ f(evt,refThrehold.current) })
        }

        refPage.current.addEventListener('intersection',refInteractionListener.current,{passive:true})
        props.setScrollCallback(handleScroll,props.index)
 
    }

    function onLoadComponent(index,refer,model){
        
        if(model && model.animation && model.animation.type==="parallax"){
            
            refParallaxElements.current.push({
                ele:refer,
                animations:model.animation.animations
            })
        }

        loadCount+=1
        // console.log('onLoadComponent', loadCount,  props.model.components)
        if(loadCount >= props.model.components.length){
            onLoadComplete()
            setLoaded(true)
        }

    }

    function onIntersect(func,index){
        refCallbacks.current[index]=func
    }

    function getComponent(model,i){
       
        let Component = DynamicComponentMap[model.component]

        if(!Component){
            console.warn('NULL Element ignoring',model)
            loadCount+=1
            return (<div className="nullcomponent"></div>)
        }

        return Component? <Component index={i} 
        model={model} 
        key={props.index+'-'+props.id+'-'+i} 
        onLoad={onLoadComponent} 
        onIntersect={onIntersect} 
        setScrollCallback={setScrollTable}
        pageIndex={props.index} 
        theme={props.theme} 
        renderNumber={renderNumber}
        format={format}
        renderOnly={props.renderOnly}
        pageState={pageState}
        setPageState={doChangeState}
        interaction={props.interaction}></Component>:'none'
    }

    return ( 
        <div className={`${styles.container} Page force3d`} style={inline}  ref={refPage} index={props.index} slideid={props.model.id} loaded={loaded?'true':'false'}  location={props.index} scroll_offset={props.model.scroll_offset?parseFloat(props.model.scroll_offset)/100:0}>
            
            <div className={`fullscreen force3d`} style={inlineContainer} >
            {
               format &&  props.model.components.map((val,i)=>{
                    return getComponent(val,i)
                })
            }
            </div>
        </div>
    )

},(prev,next)=>{
    return prev.pageState === next.pageState
    // return true 
    // JSON.stringify(prev.inline)=== JSON.stringify(next.inline)
    // return true
})


export default Page