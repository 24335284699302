import React,{useState,useEffect,useRef, useLayoutEffect, useCallback} from 'react'
import styles from './stack.module.scss'
import {getContentPath,loadImage} from 'system/AssetManager'
import StackDropUp from './StackDropUp'
import {ReactComponent as ZoomButton} from 'assets/icons/zoom.svg'
import {ReactComponent as CloseButton   } from 'assets/icons/close.svg'
import gsap,{Power2} from 'gsap/gsap-core'
import Globals from 'system/Globals'

const StackPlan  = (props)=>{

    const [planIndex,setPlanIndex] = useState(0)
    const [floorIndex,setFloorIndex] = useState(0)
    const [planModel,setPlanModel ] = useState()
    const [floorModel,setFloorModel ] = useState()
    const [floorList,setFloorList] =useState([])
    const [planList,setPlanList] =useState([])

    const refEle = useRef()
    const refPlan = useRef()
    const refImagePlan = useRef()
    const refResizeListener = useRef()
    const refServerAppListener = useRef()

    const onSelectFloor=useCallback((i)=>{
        props.onChangeFloor(props.index,props.stackModel.floors[i].floor)
        let params = new URLSearchParams(props.history.location.search)
        params.set("floor",props.stackModel.floors[i].floor)
        params.delete("plan")
        props.history.push({ pathname:props.history.location.pathname, search:params.toString()})
    },[props])

    function onSelectPlan(index){
        
        gsap.to(refPlan.current,{opacity:0, duration:0.25, ease:Power2.easeInOut ,onComplete:(i)=>{
            setPlanIndex(i)
            setPlanModel(floorModel.plans[i])
            let params = new URLSearchParams(props.history.location.search)
            params.set("plan",floorModel.plans[i].id)
            props.history.push({ pathname:props.history.location.pathname, search:params.toString()})
       
        },onCompleteParams:[index]})
    }
    function onClickZoom(evt){
        if(evt)evt.preventDefault();
        if(planModel)
        props.onZoom(planModel.plan,planModel.zoom_plan_bg)
    }
    function onClickClose(evt){
        evt.preventDefault();
        props.onClose(props.index)
    }

    let  showMarker = useCallback((index)=>{
        console.log("showing marker", index,planModel.markers[index])
        if(planModel.markers[index].files && planModel.markers[index].files.length){
            let m ={
                typeName:"gallery",
                standard:false,
                files:planModel.markers[index].files
            }
            props.setModule(m)
        }
    },[planModel, props])

    function onClickMarker(evt){
        showMarker(Number(evt.currentTarget.getAttribute("index")))
    }


    useEffect(()=>{
        if(refPlan.current)
        gsap.to(refPlan.current,{duration:0.5,opacity:1,ease:Power2.easeInOut})
    },[planModel])

    let onLoadPlanImage= useCallback(()=>{

        let img= refImagePlan.current
        if(img === undefined || !img.parentElement ) return
        let container=img.parentElement
        let prevHeight=container.clientHeight

        let scaleAdjust= props.many > 1? 0.7 : 1 
        img.style.position='absolute'
        img.style.width='auto'
        img.style.height='auto'
        img.style.width = img.clientWidth +'px'
        img.style.height= img.clientHeight+'px'
        
        container.style.height='100%'
        let scale=   parseFloat(container.clientHeight)/parseFloat(img.clientHeight)
        let w = img.clientWidth * scale
        
        if(Number(img.getAttribute('scale')) !== scaleAdjust && Number(img.getAttribute('scale'))>0){
            let h=container.clientHeight
            container.style.height=prevHeight+'px'
            gsap.to(container,{duration:0.5, ease:Power2.easeInOut, css:{scale:scale*scaleAdjust,width:(w*scaleAdjust)+'px',height:(h*scaleAdjust)+'px'}})
        }else{
            container.style.transform=`scale(${scale*scaleAdjust})`
            container.style.width=(w*scaleAdjust)+'px'
            container.style.height=(container.clientHeight*scaleAdjust)+'px'
        }

        img.setAttribute('scale',scaleAdjust)

   },[props.many])
   
   
    useEffect(()=>{
        onLoadPlanImage()
    },[onLoadPlanImage, props.many])
    
    /* SERVER EVENT CODE */
    useEffect(()=>{
        if(Globals.instance().serverApp){
            window.removeEventListener('server-event', refServerAppListener.current)
            const serverAppListener = (evt)=>{
                let detail = evt.detail
                if(!detail)return
                let arr = detail.command.split('-')
                let slot1=Number(arr[0])
                if(slot1>=Number(props.many)){
                    slot1=slot1-1
                    console.log("one missing")
                }
                if(slot1===Number(props.index)){
                if(detail.event==='stack-zoom-in-plan'){ 
                    onClickZoom()
                }
                else if(detail.event==='stack-close-plan'){ 
                    console.log("CLOSE")
                    props.onClose(props.index)
                }
                else
                 if(detail.event==='stack-select-floor'){ 
                    onSelectFloor(Number(arr[1]))
                }
                else if(detail.event==='stack-select-plan'){ 
                    onSelectPlan(Number(arr[2]))
                }
                else if(detail.event==='stack-select-marker'){ 
                   showMarker(arr[3])
                }
            }
            }
            
            refServerAppListener.current=serverAppListener
            window.addEventListener('server-event', refServerAppListener.current)
            return ()=>{
                window.removeEventListener('server-event', refServerAppListener.current)
                if(refResizeListener.current)window.removeEventListener('resize',refResizeListener.current)    
            }
        }
    },[onSelectFloor, planModel, props, showMarker])

    useEffect(()=>{
        if(refResizeListener.current)window.removeEventListener('resize',refResizeListener.current)
        refResizeListener.current=()=>{ onLoadPlanImage()}
        window.addEventListener('resize',refResizeListener.current)

        return ()=>{
            window.removeEventListener('server-event', refServerAppListener.current)
            if(refResizeListener.current)window.removeEventListener('resize',refResizeListener.current)    
        }
    },[onLoadPlanImage])


    useEffect(()=>{
        let arr = []
        props.stackModel.floors.forEach(m=>{arr.push({name:m.name})})
        setFloorList(arr)
        setFloorModel(props.floorModel)
        setFloorIndex(props.floorIndex)
        let pi=0
        let params = new URLSearchParams(props.history.location.search)
        console.log("iplan ",params.get("iplan"))
        if(params.get("iplan")){
            let iplan=params.get("iplan")
            let pindex = props.floorModel.plans.findIndex(p=>{return p.id===iplan})
            if(pindex>-1)pi=pindex  
        }

        let pm=props.floorModel.plans[pi]
        params.set("plan",props.floorModel.plans[pi].id)
        params.delete("iplan")

        props.history.push({ pathname:props.history.location.pathname, search:params.toString()})
        
        
        let f = async (plan)=>{
            
            await loadImage(getContentPath(plan.plan))
            
            let arrplan=[]
            props.floorModel.plans.forEach(p=>{arrplan.push({name:p.name})})
            setPlanList(arrplan)
            setPlanIndex(pi)
            setPlanModel(plan)
            gsap.to(refEle.current,{duration:0.5,opacity:1,ease:Power2.easeInOut})
            props.onload(props.index)
        
        } 
        f(pm)
        
        refEle.current.style.opacity=0
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.floorIndex])

   
    return (
        <div className={`${styles.planContainer} plan-container`} ref={refEle} index={props.index}>
            {floorModel &&(
                <React.Fragment>
                    {planModel && (
                        <React.Fragment>
                            <div className={`${styles.planName}`} style={Globals.instance().getThemeStyle(['mod-stack-plan-title'],props.theme)}>{floorModel.name}</div>
                            <div className={`${styles.planSub}`} style={Globals.instance().getThemeStyle(['mod-stack-plan-sub'],props.theme)}>{planModel.sub}</div>
                            <div className={`${styles.planImageContainer}`} 
                                // style={{background:'url('+getContentPath(planModel.plan)+') center/contain no-repeat'}} 
                                ref={refPlan}>
                                <div className={styles.planImageSubContainer}>
                                    <img src={ getContentPath(planModel.plan)} alt="" className={`${styles.imgPlan}`} onLoad={onLoadPlanImage} ref={refImagePlan}></img>
                                    {
                                        planModel.markers && planModel.markers.map((marker,index)=>{
                                                return <img style={marker.style} src={getContentPath(marker.markerImage)} alt="marker" key={`${floorIndex} ${planIndex} ${index}`} index={index} className={styles.planMarker} onClick={onClickMarker} onMouseDown={()=>{}}></img>
                                        })
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                    <div  className={`${styles.planSelectorContainer}`}>
                        <StackDropUp items={floorList} onSelect={onSelectFloor} keyName={'flr-selector-'+props.selectedStack} selectItem={floorIndex} theme={props.theme}
                        themeHeader={Globals.instance().getThemeStyle(['mod-stack-drop-down-header'],props.theme)}
                        themeItem={Globals.instance().getThemeStyle(['mod-stack-drop-down-item'],props.theme)}
                        themeContainer={Globals.instance().getThemeStyle(['mod-stack-drop-down-container'],props.theme)}
                        ></StackDropUp>
                        {planModel && planList.length>1 &&(
                        <StackDropUp items={planList} onSelect={onSelectPlan} keyName={'plan-selector-'+props.selectedStack} selectItem={planIndex}  theme={props.theme}
                        themeHeader={Globals.instance().getThemeStyle(['mod-stack-drop-down-header'],props.theme)}
                        themeItem={Globals.instance().getThemeStyle(['mod-stack-drop-down-item'],props.theme)}
                        themeContainer={Globals.instance().getThemeStyle(['mod-stack-drop-down-container'],props.theme)}
                        ></StackDropUp>
                        )}
                        <ZoomButton className={`${styles.btnPlanZoom}`} onClick={onClickZoom} style={Globals.instance().getThemeStyle(['mod-stack-plan-floor-btns'],props.theme)}></ZoomButton>
                        { props.hideClose !== 1 &&(
                            <CloseButton className={`${styles.btnPlanZoom}`} onClick={onClickClose} style={Globals.instance().getThemeStyle(['mod-stack-plan-floor-btns'],props.theme)}></CloseButton>
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>

    )
}

export default StackPlan