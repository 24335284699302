import './App.css';
import Main from './system/Main'
import React from 'react'
// import ControllerCursor from 'controller/ControllerCursor';
import Globals from 'system/Globals';

function App() {
  return (
    <div className={"App"}>
      
      <iframe id="analytics-frame" width="200" height="100" src="" title="analytics"></iframe>
      
      <Main></Main>  
     

    <script>console.log('APP.js')</script>
    </div>
  );
}

export default App;
