
import React,{useState,useRef,memo,useCallback,useEffect} from 'react'
import {getContentPath} from  'system/AssetManager'
import {animateParallax,getAnimationModel,animateIntersect,setInitialAnimIntersectState,setFinalAnimIntersectState} from './CanvasAnimator'
import Globals from 'system/Globals'
import history from 'system/navigation/MainRouterHistory'
import {
    Link
} from "react-router-dom";

function PNGComponent(props){
    
    
    let bLoaded=false
    const [inline,setInline] = useState({})
    const [imgSrc,setImgSrc] = useState("")
    const refEle = useRef()
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})
    // let history = useHistory();

    useEffect(()=>{ 
        refOrigOffset.current=refEle.current.offsetLeft
        if(refFormat.current && refFormat.current.animation){
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
            if(props.renderOnly===true){
                setFinalAnimIntersectState(refEle.current,refFormat.current.animation)
            }
        }
       
    },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
        animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps

    const onIntersect=  useCallback((evt)=>{
        if(evt.detail.isIntersecting )
        {
            if(evt.detail.intersectionRatio >=0.5)
                animateIntersect(refEle.current,refAnimationModel.current)  
        }
        else if(evt.detail.intersectionRatio <=0.001)
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
    },[refEle])

    useEffect(()=>{

        console.log("PNGButton props", props)
        
        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)
          
        let style ={position:'absolute',transform: 'translateZ(0), perspective: 1000px',willChange:'transform','cursor':'pointer'}
        if(refFormat.current.style){ style = Object.assign(style,refFormat.current.style) }

        
        setInline(style)
        let f =refFormat.current.file?refFormat.current.file:refFormat.current.image
        setImgSrc(getContentPath(f))
        
        if(refFormat.current.animation){
            if(refFormat.current.animation.typeName==='parallax'){
                props.setScrollCallback(onScroll,props.index)
            } 
            else if(refFormat.current.animation.typeName==='page'){
                props.onIntersect(onIntersect,props.index)
            }
        }
        return ()=>{}
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    let onClick=useCallback(()=>{
        
        
        if(props.model.content.module){
            // let url  = props.model.content.module
            // let arrURL = url.split("?")
            // let search = ""
            // let moduleid=""
            // if(arrURL.length){
            //     let urlparams= new URLSearchParams(arrURL[1])
            //     if(urlparams.has("module"))
            //         moduleid=urlparams.get("module")
            //     search = urlparams.toString()
            // }
            let url = new URL(window.location.href)
            let urlparams= new URLSearchParams(props.model.content.module)

            let pathLink = props.model.content.module;
            let isExternal = pathLink.includes('http') ||  pathLink.includes('https') ||  pathLink.includes('mailto');

            console.log('Globals.instance().useHashRouter', Globals.instance().useHashRouter, isExternal)

            if((Globals.instance().useHashRouter)&&(!isExternal))
            {
                let path = url.hash.substring(1)
                let arr=path.split('?')
                let search=''
                if(path.indexOf('?')){
                    path=arr[0]
                    search=arr[1]
                    
                }
                 search=search?'&'+props.model.content.module:props.model.content.module +"&landing=false"

                console.log(search,url.hash.substring(1)+'&'+props.model.content.module)
                
                history.push({pathname:path,search:'?'+search})

            }else{

               
                if (isExternal) {
                    console.log('isExternal')
                    // history.push({pathname:url.pathname, search:'?'+props.model.content.module})  
                    if(Globals.instance().electron) {
                        window.api.send("toApp", {"event":"external-link","link":props.model.content.module});
                        console.log('electron', props.model.content.module)
                    } else if(Globals.instance().ios) {
                        console.log('ios', props.model.content.module)
                        window.webkit.messageHandlers.notification.postMessage({ Object: 'external-link', 'link': props.model.content.module})
                        
                    } else {
                        window.open(props.model.content.module, "_blank");
                    }

                } else {
                    history.push({pathname:url.pathname, search:'?'+props.model.content.module})            
                }
                
                
            }
            
            let  moduleid=urlparams.get("module")
            
            if(moduleid){

                console.log(moduleid)
                refEle.current.dispatchEvent(new CustomEvent('narrative-pop-up', { detail:{typeName:"module",module:moduleid},bubbles:true}))
            }



            return
        }
        // 
    },[refEle,props.model])
    
    function onError(evt){
        
        if(!bLoaded && imgSrc!==""){
            console.error("PNG BTN ERROR",evt)
            Globals.instance().trackError('PNG Button Component '+imgSrc+' ',false)
            onLoad() //dont hold up the page 
            if(props.renderOnly===true){
                refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
                setFinalAnimIntersectState(refEle.current, refAnimationModel.current)
            }

        }
    }
    

    function onLoad(){
        //down res the image
        if(!bLoaded){
            props.onLoad(props.index,refEle.current,props.model)
            bLoaded=true
        }
    }
    
    return ( 
        <img className={'force3d' + (props.renderOnly === true ? " noAnim":"")} src={imgSrc} onError={onError} onClick={onClick} alt="" onLoad={onLoad} style={inline} ref={refEle}></img>
    )

}

const PNGButton = memo( PNGComponent ,(prev,next)=>{  return prev.imgSrc === next.imgSr })
export  {PNGButton}
