
import React,{forwardRef,useEffect,useRef,useState} from 'react'
import mstyles from '../modules.module.css'
import styles from './documents.module.scss'
import {getContentPath} from  'system/AssetManager'

import LoaderWheel from 'system/components/LoaderWheel'
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import gsap,{Power2} from 'gsap'
import Globals  from 'system/Globals'

const DocumentsModule = forwardRef((props, ref)=>{

    
    const refContainer = useRef()
    const [loading, setLoading] = useState(true)
    const [files, setFiles] = useState([])
    useEffect(()=>{
        let arr = []
        if(props.model.docs)
            arr=arr.concat(props.model.docs)
        if(props.model.screenshot && props.model.screenshot.document)
            arr.push(props.model.screenshot)
        setFiles(arr)
    },[props.model.docs, props.model.screenshot])

    function onClickDoc(evt){
        
        let index = Number(evt.currentTarget.getAttribute("index"))
        let d = files[Number(index)]
        let f = d.document
        if(!f )return
        if(f.indexOf("http")===-1){
            f=Globals.instance().webURL+'/media/'+f;
        }
           
        if (window.webkit && window.webkit.messageHandlers) {

            // window.webkit.messageHandlers.notification.postMessage({ Object: 'SHARE', href:f , title:'Brookfield '+f.split('/').pop()})
            if (navigator.share) {
                navigator.share({
                  title: 'Brookfield '+f.split('/').pop(),
                  url: f,
                })
                  .then(() => console.log('Successful share'))
                  .catch((error) => console.log('Error sharing', error));
              }
        }else{
            let ele= document.createElement("a")
            ele.download=f.split('/').pop()
            ele.href=f
            ele.target="_blank"
            document.body.appendChild(ele)
            ele.click()
            console.log(ele)
            document.body.removeChild(ele)
        }
    }

    useEffect(()=>{
        props.preAnimateWindowIn()
        // refContainer.current.style.display='block'
        setLoading(false)
        props.animateWindowIn()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function getNarrative(){
        
    }

    function onClickClose(evt){props.closeModule()}

    return (
        <div className={`fullscreen  ${styles.container}` } ref={ref}>
             
        { loading && (<LoaderWheel></LoaderWheel>)}

        <div className={`fullscreen  ${styles.scrollContainer} hide-scroller` } >
        
        <div className={`${styles.landingContainer}`} style={(Globals.instance().getThemeStyle(['module-background-primary'],props.theme))}>

        <div className={`${styles.letterbox}`} >
            <div className={`${styles.heading}`} style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.theme))} >
                    {props.model.title} Documents
            </div>
            

            <div className={`fullscreen  ${styles.itemsContainer}`} ref={refContainer}>
                
                { files &&
                    files.map((d,index)=>{
                        return(<div key={`docs-menu-${index}`} className={`${styles.thumbnail}`} onClick={onClickDoc} index={index}  >
                            <img src={getContentPath(d.image)} alt=""></img>
                            <div className={`${styles.caption}`} style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.theme))}>{d.caption}</div>
                        </div>)
                    })
                    
                
                }
                {console.log(props)}
            </div>
        </div>
       
        </div>
        </div>

        {( props.model.background_image && <img src={getContentPath(props.model.background_image)} alt="" className={`${styles.backgroundImage}`}/>)}


        <div className={`${mstyles.closeBtnContainer} ${styles.closeBtnContainer}`}>
                <div className={`round-btn  `} onClick={onClickClose} >
                    <CloseButton></CloseButton>
                </div>
            </div>
        </div>

        
    )

})

export default DocumentsModule
