
import React,{useEffect,useRef,useState} from 'react'
import {getContentPath} from  'system/AssetManager'
import gsap,{Power2} from 'gsap'
import Globals  from 'system/Globals'
import ControllerDPad from './Controller.DPad'
import ControllerServerComs from 'system/ControllerServerComs'
import styles from './controller-narrative.module.css'

const ControllerJoystick = (props)=>{
    // const posStickRef = useRef([0,0]);
    const posMouseRef = useRef([0.5,0.5]);
    const posMouseStartRef = useRef([0.0,0.0]);
    const mouseState = useRef("ready");
    
    // const intervalRef = useRef(null);
    const joystickRef = useRef();
    const didMoveRef = useRef(false);

    const touchStartListener = useRef();
    const touchMoveListener = useRef();
    const touchEndListener = useRef();
    const dragStateRef = useRef(false);

    function handleDragVal(evt) {
        let posx= evt.touches?evt.touches[0].clientX:evt.clientX
        let posy= evt.touches?evt.touches[0].clientY:evt.clientY
        let w = evt.target.offsetWidth;
        let h =  evt.target.offsetHeight
        // console.log("handelDragVal", posx, posy, w, h, evt)
        return [posx/w,posy/h]
    }

    function keepbounds(arr){ 
        if(arr[0]<0)arr[0]=0
        if(arr[0]>1)arr[0]=1
        if(arr[1]<0)arr[1]=0
        if(arr[1]>1)arr[1]=1
    }

    useEffect(() => {
        // console.log('props', props);
        touchStartListener.current = joystickRef.current.addEventListener('touchstart', function(e){ 
            if (mouseState.current !== "down") {
                if (props.twoFingerDrag !== true) {
                    posMouseStartRef.current=handleDragVal(e);
                    console.log('down', posMouseStartRef.current, posMouseRef.current)
                    ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-mouse-down', posMouseRef.current[0]+','+posMouseRef.current[1]);
                    dragStateRef.current = true;
                    mouseState.current = "down";
                   
                } else {
                    if (e.targetTouches.length === 2) {
                        posMouseStartRef.current=handleDragVal(e);
                        console.log('2finger down', posMouseStartRef.current,posMouseRef.current)
                        ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-mouse-down', posMouseRef.current[0]+','+posMouseRef.current[1]);
                        dragStateRef.current = true;
                        mouseState.current = "down";
                    } else {
                        if (e.targetTouches.length === 1) {
                            posMouseStartRef.current=handleDragVal(e);
                            mouseState.current = "1fstart";
                            didMoveRef.current = false;
                            console.log('1f start')
                        }
                    }
                }
            }
        });

        touchMoveListener.current = joystickRef.current.addEventListener('touchmove', function(e){
            
            let newPos= handleDragVal(e);
            let change = [newPos[0]-posMouseStartRef.current[0], newPos[1]-posMouseStartRef.current[1]]
            posMouseStartRef.current = newPos
            posMouseRef.current = [posMouseRef.current[0]+ (props.sensitivity * change[0]),posMouseRef.current[1]+(props.sensitivity * change[1])]
            keepbounds(posMouseRef.current)
            ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-move',posMouseRef.current[0]+','+posMouseRef.current[1]);
            console.log('move',posMouseRef.current)
            didMoveRef.current = true;
        });
        

        touchEndListener.current = joystickRef.current.addEventListener('touchend', function(e){ 
            if (mouseState.current === "down") {    
                console.log('up')
                ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-mouse-up', posMouseRef.current[0]+','+posMouseRef.current[1]);
                if (dragStateRef.current === true) {
                    dragStateRef.current = false;
                }          
                mouseState.current = "up";
                if (!didMoveRef.current) {
                    ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-mouse-click', posMouseRef.current[0]+','+posMouseRef.current[1])
                } else{
                    if (props.twoFingerDrag !== true) {
                         //reset to center
                        (async ()=>{
                            await new Promise(resolve => setTimeout(resolve, 100));
                            ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-move', '0.5,0.5');
                            posMouseRef.current=[0.5,0.5]
                        })()
                    }
                   
                }
                didMoveRef.current = false;
            }  else {
                
                if (mouseState.current === "1fstart") {
                    if (!didMoveRef.current) {
                        ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-mouse-click', '0.5,0.5')
                        // ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-mouse-click', posMouseRef.current[0]+','+posMouseRef.current[1])
                        console.log("1f click")
                    }
                    mouseState.current = "up";
                }
            }
        });
        
        if (props.twoFingerDrag !== true) {
            ControllerServerComs.instance().sendUDPFromControllerToServer('joystick-move', '0.5,0.5');
        }
        

        return ()=>{
            window.removeEventListener('touchstart', touchStartListener.current)
            window.removeEventListener('touchmove', touchMoveListener.current)
            window.removeEventListener('touchend', touchEndListener.current)
        }   
    }, [])

    return (      
        <section className={props.fileType}>   
            <ControllerDPad  />
            <div id="joystickHolder" ref={joystickRef} className={`${styles.joystickHolder}`}></div> 
        </section>   
    )

}

ControllerJoystick.defaultProps = { sensitivity: 1 }

export default ControllerJoystick