import Globals from 'system/Globals';
import {SET_INITIALIZED,SET_NARRATIVE_TREE,SET_NARRATIVES, SET_USER,SET_THEMES} from '../actions'

  const initialState = {
      initialized :false,
      synch_state : '',
      user:null,
      theme:{},
      themes:{},
      narratives:[],
      narrativeTree:[]
  };

  function rootReducer(state = initialState, action) {
    switch (action.type) {  
    case SET_INITIALIZED:{
        return  { ...state, intialized : action.payload}
    }
    case SET_USER:{
      if(action.payload){
        console.log('setting user!!!',JSON.stringify(action.payload))
        localStorage.setItem('user', JSON.stringify(action.payload))
        
        //send server mode login info for storing
        if(Globals.instance().serverApp){
          console.log({"event":"store-credentials",...action.payload})
          if(window.api && window.api.send)
          window.api.send("toApp", {"event":"store-credentials","user":action.payload});
        }
        console.log('electron, serverApp', Globals.instance().electron, Globals.instance().serverApp)
        if(Globals.instance().electron && !Globals.instance().serverApp){
          console.log({"event":"store-credentials-desktop",...action.payload})
          if(window.api && window.api.send)
          window.api.send("toApp", {"event":"store-credentials-desktop","user":action.payload});
        }
      }else{
        localStorage.removeItem('user')
      }
      return  { ...state, user : action.payload}
    }
    case SET_NARRATIVES:{
      return  { ...state, narratives : action.payload}
    }
    case SET_NARRATIVE_TREE:{
      return  { ...state, narrativeTree : action.payload}
    }
    case SET_THEMES+'_FULFILLED':{
      
      // eslint-disable-next-line no-unused-vars
      //find default
      // console.log(action.payload)

      for (const [key, t] of Object.entries(action.payload)) {
        
        let title=""
        if(t.name)title=t.name
        if(t.title)title=t.title
        if(title.toLowerCase()!=='default'){
          let css = t.css?t.css:{}
          let lookup = t.lookup?t.lookup:{}
          t.lookup= Object.assign({},action.payload.default.lookup,lookup)
          t.css= Object.assign({},action.payload.default.css,css)
        }
      }
      //set mappings 
      console.log('THEMES',action.payload)
      return  { ...state, themes : action.payload}
    }
    default:
        return state;
    }
  
  };
  
  export default rootReducer;