
import React,{useState,useEffect,useRef,useLayoutEffect, useCallback} from 'react'

import styles from './styles/home.module.scss'
import gsap,{Power2,Power3,Back} from 'gsap'
import { connect } from "react-redux"
import {useHistory} from 'react-router-dom'
import Globals from './Globals'
import { getContentPath } from './AssetManager'
import ScaleText from 'system/components/ScaleText'
import MiniSearch from 'minisearch'
import LoginForm from './components/LoginForm'
import Settings from './components/Settings'
import postEvent from './components/UserEvent'
import ControllerServerComs from 'system/ControllerServerComs';
// import ControllerSettings from './components/ControllerSettings';
import {ReactComponent as Logo} from 'assets/home/logo.svg'
import {ReactComponent as IconHamburger} from 'assets/icons/burger.svg';
import {ReactComponent as IconSearch} from 'assets/icons/search.svg'
import {ReactComponent as IconClose} from 'assets/icons/close.svg'
import {ReactComponent as IconCaretRight} from 'assets/icons/caret_right.svg'
import {ReactComponent as BackIcon} from 'assets/icons/arrow_left.svg'
import {ReactComponent as Question} from 'assets/icons/help.svg'
import {ReactComponent as IconSettings} from 'assets/icons/settings.svg'
// import axios from 'axios'
import {setUser} from 'store/actions'


const HomeVideo = React.memo(React.forwardRef((props,ref)=>{
  return (<video ref={ref} key={`home-vid-bg`} className={`fullscreen ${styles.backgroundVideo} constant`} muted loop autoPlay src={props.src} alt="" open={props.open}
   onLoadedMetadata={props.onLoadedMetadata} 
   id="home-bg-video" ></video>)

}))
const videoPath = getContentPath('core/home/bg.mp4?d='+((new Date()).getTime()),true)
    

const Home= (props)=>{
    
    const [currentPath,setCurrentPath] = useState()
    const [menuOpen,setMenuOpen] = useState(false)
    const [pageState, setPageState] = useState(props.user?"menu":"login")
    const [tick , setTick] = useState(false)
    let history = useHistory();
    const [depth1,setDepth1]= useState(-1)
    const [depth2,setDepth2]= useState(-1)
    const [depth3,setDepth3]= useState(-1)
    // eslint-disable-next-line no-unused-vars
    const [depth4,setDepth4]= useState(-1)
    
    const [group2,setGroup2]= useState(null)
    const [group3,setGroup3]= useState(null)
    const [group4,setGroup4]= useState(null)
    
    const [menuTree,setMenuTree] = useState(Object.assign([],props.narratives))

    const [bgHighlight ,setBGHighlight]= useState(false)
    
    const col1 = useRef(null);
    const col2 = useRef(null);
    const col3 = useRef(null);
    const col4 = useRef(null);
    const refContainer = useRef(null);
    const refVideo = useRef();
    const refSlogan = useRef(null);
    const refBGImage = useRef(null);
    const refQ = useRef()
    const [showGetStarted, setShowGetStarted]= useState(localStorage.getItem('getStarted'))
    const isMounted = useRef(false)

    const [render,setRender]=useState(0)

    const [helpId, setHelpId] = useState(null);
    
    const [searchState,setSearchState] = useState('')
    const [searchStateResults,setSearchStateResults] = useState([])
    const refSearch = useRef(null)
    // const refNarrativeSlide=useRef(null)
    const refLogin = useRef(null)
    const refSettings = useRef(null)

    const miniSearch = useRef()
    const refMyPropertiesWrapper = useRef()

    const [loadPage, setLoadPage] = useState(false);
    const [showSizeForm, setShowSizeForm] = useState(false);

    useEffect(()=>{

        console.log('mini search')
        miniSearch.current = new MiniSearch({fields: ['title', 'meta'],
        storeFields: ['title', 'id'], // fields to return with search results
        searchOptions:{
            boost:{title:2},
            fuzzy:0.2,
            prefix: true
        }
      })
    
    if (props.user) {
        props.narratives && miniSearch.current.addAll(props.narratives.filter(n=>{
            return props.user.narratives.includes(n.id);
        })) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[miniSearch])
    
    useEffect(()=>{
        let tree;
        let lookup={}
        // if (props.narrativeTree) {
        //     tree = JSON.parse(JSON.stringify(props.narrativeTree))
        // }
        
        
        if(props.user){
            props.user.narratives.forEach( l=>{lookup['n'+String(l)]=true})



        }
        //  const recurseMarkPrune=(t,p)=>{
        //      let count =0 
             
        //      t && t.forEach(titem =>{
        //         if(titem.narrative){ 
        //              if(lookup['n'+String(titem.narrative)] || (Globals.instance().mode === 'static-web') ){
        //                  titem.actives=1
        //                  count++;
        //              }
        //              else
        //                  titem.actives=0

        //          }else{
        //              titem.active=0
        //          }
        //          if(titem.children && titem.children.length){ count= count +recurseMarkPrune( titem.children,titem)}
        //      })
        //      if(p){
        //          p.actives=count         
        //     }
        //      return count
        //  }
        
        //   recurseMarkPrune(tree)
   
        setMenuTree(props.narratives)

        let id;
        if ((Globals.instance().serverApp)||(Globals.instance().controllerApp)) {
           id = Globals.instance().narrativeHelpController
        } else if (Globals.instance().presentationMode) {
           id = Globals.instance().narrativeHelpLargeTouch
        } else {
           id = Globals.instance().narrativeHelp
        }
        setHelpId(id);
        console.log('helpID', id);
        
       console.log('props.narratives', props.narratives)

    },[props.narratives, props.user])


    useEffect(()=>{
        
        if(!props.user && pageState!=="login" )
        {
            setPageState("login")
            setBGHighlight(false)
            setMenuOpen(false)
            history.replace({pathname: '/home'})
            console.log('force login')
        }else{
        
            if(Globals.instance().serverApp) return
            
            setDepth1(props.match.params.d1)
            setDepth2(props.match.params.d2)
            setDepth3(props.match.params.d3)
            setDepth4(props.match.params.d4)
            
            let grp2 =null
            let grp3 =null
            let grp4 =null

            //group 2
            if((props.match.params.d1)&&(menuTree)){
                grp2=menuTree.find((ele)=>{ return String(ele.id) === props.match.params.d1})
                setGroup2(grp2)
                setMenuOpen(true)
                setBGHighlight(true)
                setPageState("menu")
            }else
                setGroup2(null)

            //group 3
            if(grp2 &&props.match.params.d2){
                grp3=grp2.children.find((ele)=>{return String(ele.id) === props.match.params.d2})
                setGroup3(grp3)
            }else setGroup3(null)

            //group 4
            if(grp3 && props.match.params.d3){
                grp4=grp3.children.find((ele)=>{return String(ele.id) === props.match.params.d3})
                setGroup4(grp4)
            }else setGroup4(null)

        if(props.location.pathname==='/home/myproperties/')
        {
            setMenuOpen(true)
            setPageState("myproperties")
            setBGHighlight(true)
            
        }
        else if(props.location.pathname==='/home/search/')
        {
            setMenuOpen(false)
            setBGHighlight(true)
            setPageState("search")
        }
        else if(props.location.pathname==='/home/settings/')
        {
            setMenuOpen(true)
            setBGHighlight(true)
            setPageState("settings")
        }
        else if( props.location.pathname==='/home' && pageState!=="login")
        {
            setPageState("menu")
        }
       
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.location,props.match])

    useEffect(()=>{
    //     console.log(bgHighlight)
    if(isMounted.current)
        if(refVideo.current){
            if(bgHighlight)
                refVideo.current.setAttribute('open','')
            else 
                refVideo.current.removeAttribute('open')
        }
    //     // if(bgHighlight){  gsap.to(refBGImage.current,{duration:0.5,opacity:0.4, filter:'blur(10px)'})}
    //     // else{ gsap.to(refBGImage.current,{duration:0.5,opacity:1, filter:'blur(0px)'})}
    },[bgHighlight])
    
    useEffect(()=>{
        if(refSearch.current){ refSearch.current.value=searchState}
    },[searchState])

    useEffect(()=>{
        if(pageState==="search")
        refSearch.current.focus()
        if(pageState==="login")
        gsap.from(refLogin.current,{opacity:0,duration:1,delay:0.5})
        // if(pageState==="settings")
        // gsap.from(refSettings.current,{opacity:0,duration:1,delay:0.5})
        
    },[pageState])

    useEffect(()=>{
        
        if(pageState!=="login" ){
             if(!menuOpen){
                localStorage.setItem('getStarted',"false")
                gsap.killTweensOf(refQ.current)
                gsap.to(refQ.current,{duration:0.35,delay:0.3,opacity:1.0,scale:1.0, ease: Back.easeInOut})    
                
                if(refQ.current && refQ.current.nextElementSibling){
                    gsap.to(refQ.current.nextElementSibling,{duration:0.35,delay:0.35,opacity:1.0,scale:1.0, ease: Back.easeInOut})
                }
            }
        }
    },[menuOpen,pageState])

    useLayoutEffect(()=>{
       
        if(isMounted.current === false) { console.log("unmounted");return}
        
        let c1 = col1.current.querySelector('.'+styles.column1Container)
        
        if( menuOpen ){
            gsap.killTweensOf(c1)
            gsap.to(col1.current,{duration:0.25,css:{height:Globals.instance().presentationMode?'84%':'100%'},onComplete:()=>{
                c1.style.display='block'
                if(pageState==="settings"){
                    if(refSettings.current){
                    refSettings.current.style.display='block'
                    c1.style.opacity=1;
                    gsap.to(refSettings.current,{duration:0.5,opacity:1,ease:Power3.easeInOut})
                    }
                }else{
                    animateMenuContainerItems(c1)
                    animateMenuContainerItems(col2.current)
                    animateMenuContainerItems(col3.current)
                    animateMenuContainerItems(col4.current)
                }
                window.dispatchEvent(new CustomEvent('hide-initial-bg'))
            },ease:Power3.easeInOut})
            gsap.to(refSlogan.current,{duration:0.35,opacity:0,ease:Power3.easeInOut})

        } else {
            col1.current.querySelector('.'+styles.column1Container).style.display='block'
            gsap.to(c1 ,{duration:0.35,ease:Power3.easeInOut,opacity:0, onComplete:(c1Container,c,eleSlogan)=>{
                c.style.display='none'
                Array.from(c1Container.querySelectorAll('.'+styles.columnItem)).forEach((ele,i)=>{ele.style.opacity=0})
                let temp = window.getComputedStyle(c1Container).height
                c1Container.style.height='auto'
                if(pageState==="menu" && eleSlogan){
                    eleSlogan.style.display='block'
                    gsap.to(eleSlogan,{duration:0.5,delay:0.3,opacity:1,ease:Power3.easeInOut})
                }
                if(pageState==="search" && eleSlogan){
                    eleSlogan.style.display='none'
                    eleSlogan.style.opacity=0                    
                }
                
                let h=window.getComputedStyle(c1Container).height
                c1Container.style.height=temp
                gsap.to(c1Container,{duration:0.35,css:{height:h},ease:Power2.easeInOut})
                
            }
            ,onCompleteParams:[col1.current,c1,refSlogan.current]})
        }
        if(menuOpen===false || pageState==="myproperties" ){
            if(col2.current)
            gsap.to(col2.current,{duration:0.25,opacity:0,onComplete:(cele)=>{Array.from(cele.querySelectorAll('.'+styles.columnItem)).forEach((ele,i)=>{ele.style.opacity=0})}, onCompleteParams:[col2.current]})
            if(col3.current)
            gsap.to(col3.current,{duration:0.25,opacity:0,onComplete:(cele)=>{Array.from(cele.querySelectorAll('.'+styles.columnItem)).forEach((ele,i)=>{ele.style.opacity=0})}, onCompleteParams:[col3.current]})
            if(col4.current)
            gsap.to(col4.current,{duration:0.25,opacity:0,onComplete:(cele)=>{Array.from(cele.querySelectorAll('.'+styles.columnItem)).forEach((ele,i)=>{ele.style.opacity=0})}, onCompleteParams:[col4.current]})
            if(pageState === "myproperties" && refMyPropertiesWrapper.current.lastElementChild){
                let r = refMyPropertiesWrapper.current.lastElementChild.getBoundingClientRect()
                refMyPropertiesWrapper.current.style.width = (r.x+r.width )+'px'
            }
        }
    },[pageState,menuOpen,currentPath])

    useEffect(() => {

        if ((Globals.instance().controllerApp)&&(props.user && props.user.device_sn)) {
            if (Globals.instance().usbRequested === false) {
              
                Globals.instance().usbRequested = true;          
                ControllerServerComs.instance().sendUDPFromControllerToServer('assign-usb-device', props.user.device_sn)
                console.log('passing arduino sn:', props.user.device_sn)

                
            } 
        }
      
   
       
    }, [props.user])



    useEffect(()=>{ 
        let c1 = col1.current.querySelector('.'+styles.column1Container)
        gsap.killTweensOf(c1)
        gsap.to(col1.current,{duration:0.25,css:{height:Globals.instance().presentationMode?'84%':'100%'},onComplete:()=>{
            c1.style.display='block'
            if(pageState==="settings"){
                if(refSettings.current){
                refSettings.current.style.display='block'
                c1.style.opacity=1;
                gsap.to(refSettings.current,{duration:0.5,opacity:1,ease:Power3.easeInOut})
                }
            }else{
                animateMenuContainerItems(c1)
                animateMenuContainerItems(col2.current)
                animateMenuContainerItems(col3.current)
                animateMenuContainerItems(col4.current)
            }
            window.dispatchEvent(new CustomEvent('hide-initial-bg'))
        },ease:Power3.easeInOut})
        gsap.to(refSlogan.current,{duration:0.35,opacity:0,ease:Power3.easeInOut})
  

        isMounted.current=true
        Globals.instance().nid=null
        Globals.instance().trackPage('home/')

        function handleResize() {
            // Set window width/height to state
            if(!col1.current.hasAttribute("open")){
                col1.current.style.height='auto'
                let h=window.getComputedStyle(col1.current).height
                col1.current.style.height=h
            }
          }
     window.addEventListener("resize", handleResize);
        setTick(true)
        
        const onRenderHome=()=>{
            console.log('render-home')
            Globals.instance().updatebookmarks()

            setRender(1+Number(refContainer.current.getAttribute("render")))    
            
          }
          window.removeEventListener('render-home',onRenderHome)
          window.addEventListener('render-home',onRenderHome)
    
         
        return () => {
            isMounted.current = false
            window.removeEventListener("resize", handleResize)
            window.removeEventListener('render-home',onRenderHome)
          
            
        };
           
    
    },[])
 

    let  onClickTier = useCallback((evt)=>{

        console.log('evt', evt)

        let path='/home'
        let index =evt.currentTarget.getAttribute('nid')
        let d1 =evt.currentTarget.getAttribute('d1')
        let d2 =evt.currentTarget.getAttribute('d2')
        let d3 =evt.currentTarget.getAttribute('d3')
        let d4 =evt.currentTarget.getAttribute('d4')

        let narrative = evt.currentTarget.getAttribute('narrative')
        let size = parseInt(evt.currentTarget.getAttribute('size'))
        
        if(d1)path=path+'/'+d1
        if(d2)path=path+'/'+d2
        if(d3)path=path+'/'+d3
        if(d4)path=path+'/'+d4

        function goToPresentation(){
            if(narrative){
                setLoadPage(true)
                Globals.instance().homeLink=history.location.pathname
                
                if(Globals.instance().controllerApp){
                    history.push('/controllernarrative/'+narrative)
                    console.log('controller click narrative')
                    return;
                }
                else if (Globals.instance().ios){
                    window.webkit.messageHandlers.notification.postMessage({ Object: 'present-narrative', narrative:narrative })
                    return
                }
    
                path=path+'/'+index
                setCurrentPath(path)
                history.push(path)
                let params = new URLSearchParams(props.history.location.search)
                params.set('narrative',narrative)
                gsap.delayedCall(0.25,(h,p,s)=>{h.push({pathname:p,search:s})},[history,props.history.location.pathname,params.toString()])
                
            }
            else if(currentPath !== path){
                path=path+'/'+index
                gsap.delayedCall(0.0,(h,p)=>{h.push(p)},[history,path])
                setCurrentPath(path)
            }        
            else{
                path=path+'/'+index
                history.push({pathname:path});
                setCurrentPath(path)
            }
            if(!menuOpen) setMenuOpen(true)
        }
        let hideDownload = Globals.instance().downloaded
        let hideList = [];
        console.log('hideDownload', hideDownload)
        if (hideDownload) {
           
            let ids = hideDownload;
            if (Array.isArray(ids) && ids.length > 0) {
                ids.forEach(o => hideList.push(o))
            } else if (!Array.isArray(ids)) {
                hideList.push(ids)
            } else {
                
            }
        }
        
       
        console.log('hideList', hideList, narrative)
        

        if ((size > 0) && !loadPage && Globals.instance().ipad && (!(hideList.indexOf(narrative) > -1))) {
            // TEMP WORKAROUND; NEED TO FIX IF IPAD APP NEEDS TO BE REDEPLOYED TO THE APP STORE
            // Globals.instance().showAlert('Download Required', "This presentation is " + size + "Mb. Do you want to continue?",true,goToPresentation)
            goToPresentation()
        } else {
           
            goToPresentation()
        }
        
       

    },[currentPath, history, menuOpen, props.history.location.pathname, props.history.location.search])
    
    let  animateMenuContainerItems=(container)=>{
        if(container){
            // let items = Array.from(container.querySelectorAll('.'+styles.columnItem))
            // container.style.display='block'
            
            // if(items.length>0){
            //     container.style.opacity=1
            // }else{
            //     container.style.opacity=0    
            // }
            // items.forEach((ele,i)=>{
            //     let opacity =1;
            //     if((ele.getAttribute('c')==='0' || ele.getAttribute('actives')==='0') && ele.getAttribute('narrative')===null){
            //         opacity=0.5;
            //         ele.style['pointer-events']='none'
            //     } else{
            //         ele.style['pointer-events']='all'
            //     }
            //     gsap.to(ele,{duration:0.35,opacity:opacity,ease:Power3.easeInOut,delay:(i+1)*.03})
            // }) 
        }
    }

    function onLogin(){ 
        setPageState("menu") 
        // Update this properly
        window.location.reload(false);
    }

    function logout(){
        props.setUser(null)
        setPageState("login")
        setBGHighlight(false)
        setMenuOpen(false)
        history.replace({pathname: '/home'})
    }
    
    function onClickSettings(){
        if(pageState!=="settings"){
        setPageState("settings")
        setBGHighlight(true)
        setMenuOpen(true)
        history.push('/home/settings/')
        }

    }

    async function onClickMenu(){
        // console.log("menumenu")
        setPageState("menu")
        // let c1 = col1.current.querySelector('.'+styles.column1Container)
            
        if(!menuOpen){
            setMenuOpen(true)
            setBGHighlight(true)
        }else{
            setBGHighlight(false)
            setMenuOpen(false)
        }
    }

    function onClickClose(){
        history.push(Globals.instance().getRoute('/home'))
        setBGHighlight(false)
        
    }

    async function onClickSearch(){ 
        
        gsap.to(refSlogan.current,{duration:0.25,opacity:0,
            onComplete:(eleSlogan)=>{
                eleSlogan.style.display='none'
            history.push('/home/search/')
        },onCompleteParams:[refSlogan.current]})

        // history.push('/home/search/')
    }
    function onClickMyProperties(evt){  
        history.push('/home/myproperties/')
        Globals.instance().homeLink=history.location.pathname
    }

    function onClickBookmark(evt){ 
        let narrative=evt.currentTarget.getAttribute('narrative')
        Globals.instance().homeLink=history.location.pathname
        if (Globals.instance().ios){
            window.webkit.messageHandlers.notification.postMessage({ Object: 'present-narrative', narrative:narrative })
            return
        }
        let params = new URLSearchParams(props.history.location.search)
        params.set('narrative',narrative)
        props.history.push({pathname:props.history.location.pathname,search:params.toString()})
    }
    function onChangeSearch(evt){
        let val= evt.target.value
        setSearchState(val)
        // console.log(props.user)
        let results = miniSearch.current.search(val)
        setSearchStateResults(results)
        if(document.activeElement!==refSearch.current)
        refSearch.current.focus()
    }
    function onLoadBG(){
        if(!refContainer.current.classList.contains('animin')){
            refContainer.current.classList.add('animin')
            gsap.to(refContainer.current,{duration:1,opacity:1, ease:Power3.easeInOut})
            gsap.from(col1.current,{duration:0.75, left:'-19%',opacity:0, delay:0.25,ease:Power3.easeInOut})
        }
        refBGImage.current.style.visibility='visible'
    }
    function onLoadBGVideo(evt){
        console.log('onLoadBGVideo')
        if(!refContainer.current.classList.contains('animin')){
            refContainer.current.classList.add('animin')
            gsap.to(refContainer.current,{duration:1,opacity:1, ease:Power3.easeInOut})
            gsap.from(col1.current,{duration:0.75, left:'-19%',opacity:0, delay:0.25,ease:Power3.easeInOut})
        }
        evt.target.style.visibility='visible'
        
        // POST USER EVENT
        // props.user && postEvent(props.user.username, {"event": "page-enter", "page": "home", "page_title": "home", "page_id": "0", "search": ""}, props.user.market, props.user.category);
        // console.log('props.user', props.user)

  
    }
    function onClickSearchResult(evt){
        Globals.instance().homeLink=history.location.pathname
        let params = new URLSearchParams(props.history.location.search)
        let narrative =evt.currentTarget.getAttribute("narrative")
        
        if(Globals.instance().controllerApp){
             history.push('/controllernarrative/'+narrative)
                return;
        } else if (Globals.instance().ios){
            window.webkit.messageHandlers.notification.postMessage({ Object: 'present-narrative', narrative:narrative })
            return
        }
        params.set('narrative',narrative)
        gsap.delayedCall(0.25,(h,p,s)=>{h.push({pathname:p,search:s})},[history,props.history.location.pathname,params.toString()])
    }

    function onClickQuestion(evt){
        console.log('question')
        // if (Globals.instance().controllerApp) {
        //     history.push('/controllernarrative/'+helpId)
        //     ControllerServerComs.instance().sendUDPFromControllerToServer('navigate','/narrative/'+helpId)  
        //     return;
        // } else {
        //     let params = new URLSearchParams(props.history.location.search)
        //     let narrative = evt.currentTarget.getAttribute("narrative")
        //     if (Globals.instance().ios){
        //         window.webkit.messageHandlers.notification.postMessage({ Object: 'present-narrative', narrative:narrative })
        //         return
        //     } 
        //     params.set('narrative',narrative)
        //     gsap.delayedCall(0.25,(h,p,s)=>{h.push({pathname:p,search:s})},[history,props.history.location.pathname,params.toString()])
        // }
        
    }

    function getBookmark(val,index){
        let obj = props.narratives.find(o=>{ return o.id===val})
        // console.log(val,props.narratives)
        if(obj) return (                                            
         <div key={`book-rsul${val}${index}`} className={`${styles.searchResult}`} narrative={val} onClick={onClickBookmark}>{  ((v)=>{ 
                                            return obj?obj.title:''
                                         })(val)}</div>)
        else return
    }

    function quit() {
        if(Globals.instance().electron){
            if(window.api && window.api.send) {
                window.api.send("toApp",  {"event":"quit-app"});
                console.log('quit clicked');
                return
            }
            
        }
    }

    function reload() {
        if(Globals.instance().electron){
            if(window.api && window.api.send) {
                window.api.send("toApp",  {"event":"reload-app"});
                console.log('reload clicked');
                return
            }
        }
    }




    return( 
        
        <div className={`${styles.container} fullscreen `} ref={refContainer} path={currentPath} render={render}>
                
                {/* <img className={`fullscreen ${styles.backgroundImage}`} alt="" src={getContentPath('core/home/bg.png',true)} open={bgHighlight ?true:false} ref={refBGImage} onLoad={onLoadBG} key={`home-bg-image`}></img> */}
                
                {tick && (
                    <HomeVideo key={`home-video`} src={videoPath} alt="" open={bgHighlight ?true:false} 
                    onLoadedMetadata={
                        (evt)=>{
                            // console.log("he")
                            onLoadBGVideo(evt)
                        }
                    } 
                    id="home-bg-video" ref={refVideo} />
                 )}

                <div ref={col1} className={`${styles.column} ${styles.column1} ${pageState}` } open={menuOpen?true:false}>
                    
                {pageState==="search" && (
                    <div className={`${styles.searchContainer}`} >
                        
                        <div className={`round-btn`} onClick={onClickClose}><BackIcon></BackIcon></div>
                        <div className={`${styles.searchFieldContainer}`}>
                            <label className={`${styles.searchLabel}`} >Search</label>
                            <input className={`${styles.searchInput}`} ref={refSearch} type="search" size="100" name="search" placeholder="Property Name" value={searchState} onChange={onChangeSearch} key={'searchinput'} autoComplete="off"></input>
                        </div>
                    </div>
                )}
                

                {pageState!=="search" && (
                    (props.user && props.user.org && props.user.org.logo) ? <img src={getContentPath(props.user.org.logo)} alt="logo" className={`${styles.logo} logo`} /> : <Logo alt="" className={`${styles.logo} logo`} />
                )}
                
                    <div className={`${styles.column1Container}  `} >
                        {pageState === 'menu' &&<h1>Presentations</h1> }
                        {
                            pageState === 'menu' &&  menuTree && menuTree.filter(a => a.status === "live").map((narrative,index)=>{
                                return (
                                <div 
                                    className={`${styles.columnItem}`} 
                                    key={`nav1 ${narrative.id} ${narrative.title} ${index}`} 
                                    index={index} 
                                    menu={narrative.title}  
                                    nid={narrative.id}
                                    size={narrative.size}
                                    narrative={narrative.id}
                                    c={1}
                                    onClick={onClickTier}  
                                   > 
                                    {narrative.screenshot && <img className={`${styles.columnThumb}`} src={getContentPath(narrative.screenshot)} alt="screenshot" />}
                                    <h2>{narrative.title}</h2>
                                    
                                    </div>
                                    )
                            })
                        }
                    
                       {  pageState === 'settings' && props.user && (<Settings user={props.user.username} userObj={props.user} logout={logout} ref={refSettings}></Settings>)
                    }

                    </div>
                    {/* <div className={`${styles.spacer}`}></div> */}
                    
                    
                    { 
                    pageState ==="login" && (<LoginForm ref={refLogin} onLogin={onLogin}></LoginForm>)
                    }
                    {
                    pageState !=="login" && (
                        <ScaleText ref={refSlogan}
                        key="home-logo"
                        containerStyle={{width:'77%'}}
                        className={`${styles.slogan}` }
                        viewBox={"0 0 283 110"} 
                        html={`Presentation`}
                        ></ScaleText>
                     )}

                </div>
                    
                
                {
                    pageState==="search" && (
                        <div className={`${styles.searchResultsContainer} hide-scroller`}>
                            {
                                searchStateResults.map((val,index)=>{
                                    return (
                                        <div key={`search-rsul${val.title}${index}`} narrative={`${val.id}`} className={`${styles.searchResult}`} onClick={onClickSearchResult} >{val.title}</div>
                                    )
                                })
                            }
                        </div>
                        )
                }
                {
                    pageState==="myproperties" && (
                        <div className={`${styles.myPropertiesContainer} hide-scroller`}>
                            <div className={`${styles.myPropertiesWrapper}`} ref={refMyPropertiesWrapper}>    
                            {
                                Globals.instance().updatebookmarks() || 
                                (Globals.instance().bookmarks && Globals.instance().bookmarks.map((val,index)=>{
                                    return (
                                        <React.Fragment> { getBookmark(val,index) }</React.Fragment>
                                    )
                                }))
                            }
                            </div>
                        </div>
                        )
                }
                
                

               <div className={`buttonContainer ifServerHide`}>
               {
                props.user && (
                 <div style={{display: "none"}} className={`buttonContainerCol `}>
                  <div style={{display: "none"}} className={`round-btn`} onClick={onClickMenu}>
                    <IconHamburger></IconHamburger>
                   </div>
                    { pageState!=="search" && !Globals.instance().presentationMode && (
                            <div style={{display: "none"}} className={`round-btn`} onClick={onClickSearch}>
                                <IconSearch></IconSearch>
                            </div>
                        )
                    }
                    { (pageState==="search" ||  pageState==="myProperties") && (
                            <div className={`round-btn`} onClick={onClickClose}>
                                <IconClose></IconClose>
                            </div>
                        )}
                   </div>
                    )}
                <div className={`buttonContainerCol `}>
                 {
                        props.user && (

                            <div className={`settings ${styles.logoutContainer}`}>
                                  { pageState!=="settings" && (<div className={`round-btn`} onClick={onClickSettings}><IconSettings></IconSettings></div>)}
                                  { pageState==="settings" && (<div className={`round-btn`} onClick={onClickClose}><IconClose></IconClose></div>)}
                                {/* <div className={`round-btn`} onClick={logout}>Logout</div> */}
                            </div>
                        )
                    }
                    { pageState !== 'login' && 
               <div className={`${styles.helpContainer}`} open={menuOpen} style={{display: "none"}}>
                    
                    <div className={`round-btn`} narrative={helpId} onClick={onClickQuestion} ref={refQ}><Question></Question></div>
                    {showGetStarted==="true" && 
                    <div className={`${styles.helpCtaContainer}`}>
                        Click here for a quick<br></br>overview of the app
                        <div className={`${styles.helpCtaArrow}`}></div>
                    </div>
                    }
                </div>
                }

                </div>
               </div>
               
                <div className={styles.versionNote}> 
                    { Globals.instance().controllerApp && <span>controller app</span>}
                    {Globals.instance().serverApp && <span>controller mode</span>}
                </div>
            
            {Globals.instance().presentationMode && (
                <span>
                    <div className={`fcenter ${styles.presentationQuit}`} onClick={quit}> Quit </div>
                </span>
            )}
    </div> 
    )
                
    
}


function mapDispatchToProps(dispatch) {
    return {setUser: val => dispatch(setUser(val))};
}
  
const mapStateToProps = state => {
    const {narratives,narrativeTree,user} = state
    return { narratives:narratives , narrativeTree:narrativeTree,user};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home)