
import React,{useEffect,useRef,useState} from 'react'
import {fetchCMSJSONs} from 'system/AssetManager'
import NarrativeMenuItem from 'narrative/NarrativeMenuItem'
// import gsap,{Power2} from 'gsap'
import {loadContentFile, getContentPath} from 'system/AssetManager'
import Globals  from 'system/Globals'
import styles from './controller-narrative.module.css'
import nstyles from '../narrative/narrative.module.scss'
import ControllerServerComs from 'system/ControllerServerComs'
import ControllerStackThumb from './ControllerStackThumb'
import ControllerGalleryPopup from './ControllerGalleryPopup'

const ControllerStack = (props)=>{

    const [stacks,setStacks] = useState([])
    const [stack,setStack] = useState()
    const [menuItems,setMenuItems] = useState([])
    const [selected,setSelected] = useState(0)
    const [planCount,setPlanCount] = useState(0)
    const [styleMenu,setStyleMenu]=useState({})
    const [styleMenuSelected,setStyleMenuSelected]=useState({})
    const [activePage, setActivePage] = useState(null)
    const [landingParams, setLandingParams] = useState(null)
    const [currentModule,setCurrentModule] = useState(null)
    const [srcSVG, setSrcSVG] = useState(null);
    
    const refPopup = useRef()
    
    useEffect(()=>{
        console.log(props.params)
        //load json 
        setActivePage(props.activeNarrativePageIndex);
        // props.setActiveNarrativePageIndex(null);
        let arrMenuItems=[]
        let load = async ()=>{
            let arrStackPaths=[]
            props.module.stacks.forEach((s)=>{arrStackPaths.push(s.content)})
            let res = await fetchCMSJSONs(arrStackPaths)
            let countPlans = 0
            res.forEach( (s,i)=>{
                if(s.floors){
                    s.floors.forEach(f=>{
                    if(f.plans)countPlans+=f.plans.length
                    })
                }
                 arrMenuItems.push({label:props.module.stacks[i].name,stack:s.id,index:arrMenuItems.length})
            })
            setPlanCount(countPlans)
            setStacks(res)
            setMenuItems(arrMenuItems)
            console.log(res,props.module)

            if(props.params){
                let params =new URLSearchParams(props.params);
                let stack = params.get('stack')
                let plan = params.get('plan')
                let mIndex= res.findIndex(s=>{return s.id===stack})
                mIndex= mIndex>=0?mIndex:0
                setSelected(mIndex)
                setStack(res[mIndex])
                //find the landing plan 
                res[mIndex].floors.forEach((f,findex)=>{
                    f.plans.forEach((p,pindex)=>{
                       if(p.id===plan){console.log("landing",findex,pindex);setLandingParams(findex+","+pindex)}
                    })
                })
            

            }else{
                setSelected(0)
                setStack(res[0])
            }
            

        }
        load()

        setStyleMenu(Globals.instance().getThemeStyle(['nav-menu-item'],props.theme))
        setStyleMenuSelected(Object.assign({},Globals.instance().getThemeStyle(['nav-menu-item'],props.theme),{color:'var(--primary-accent)', '--hover-color': 'var(--primary-accent)'}))
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(()=>{
        if(stacks.length) setStack(stacks[selected])
    },[stacks, selected])

  
    function onClickStack(evt){
        let index=Number(evt.currentTarget.getAttribute("index"))
        
        setSelected(index)
        ControllerServerComs.instance().sendUDPFromControllerToServer('stack-click-stack',index)   
    }

    const onClickBack = () => {
        props.setCurrentModule({typeName: 'controller-narrative'});
        props.setActiveNarrativePageIndex(activePage);
        props.setAnimInCurrentModule(false);
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-close-module');
     }

     function onClickMarker(marker){
            setCurrentModule(marker)
     }

     function onClickBaseBtn() {
        ControllerServerComs.instance().sendUDPFromControllerToServer('stack-click-basestack');
        console.log('onClickBaseBtn')
     }
    //  useEffect(() => {
    //     if(currentModule){
    //         gsap.to(refPopupContainer.current, {opacity: 1, duration: 0.3})
    //         if(refPopupContainer.current.firstElementChild)refPopupContainer.current.firstElementChild.style.opacity=1
    //     }
    // }, [currentModule, fileType])

//  function zoomIn(){
//      let p = filteredPlans[stackSelected]

//     ControllerServerComs.instance().sendUDPFromControllerToServer('module-stack-zoom-in',p.plan+','+p.zoom_plan_bg)   
//      setZoomedIn(true)
//  }
//  function zoomOut(){
//     ControllerServerComs.instance().sendUDPFromControllerToServer('module-stack-zoom-out','')   
//     setZoomedIn(false)
// }
    return (
        <React.Fragment>
           <div className={`${styles.linksContainer} `} >
                     <h3>Stack</h3>
                     <div className={`${styles.linksScrollContainer} hide-scroller`} >   
                            
                     {
                       menuItems &&  menuItems.map((val,index)=>{
                            return(
                             <NarrativeMenuItem
                             model={val}
                             index={index}
                             key={`ctrl-stack-menu-link-${index}`} 
                             onClickLink={ onClickStack } 
                             onExpand={()=>{}}
                             className={nstyles.menuLink+' '+styles.controllerMenuLink }
                             theme={props.theme}
                             style={selected===index ? styleMenuSelected : styleMenu}
                              ></NarrativeMenuItem>
                            )
                        })
                    }
                    </div>
            </div>

            { 
                    
            <section className={`${styles.moduleHolder} hide-scroller`}>

            <div className={`${styles.stackBaseThumb}`}>
                <div onClick={onClickBaseBtn} className={`${styles.stackBaseBtn}`}>Stack Plan</div>
            </div>
               
                <div className={`${styles.moduleThumbsScrollContainer} `}>
                    <div className={`${styles.stackThumbnails} ${styles.stackThumbnails}` }>
                        {[0,1,2].map((id,i) => ( <ControllerStackThumb index={i} key={`stack${selected}-thumb-${id}`} stack={stack} onClickMarker={onClickMarker}  planCount={planCount} landingParmas={i===0 && landingParams?landingParams:null}></ControllerStackThumb>))}
                    </div>
                </div>
              
                <div className={`${styles.moduleThumbsScrollContainerGradient}`}></div>
            </section>   
        
                }
                
                {props.showBackBtn===true &&  <div className={`${styles.backBtnRow} ${styles.backBtnRowOver}`}>
                    <div className={`${styles.pillBtn}`} onClick={() => onClickBack()}>Back to Presentation</div>
                </div>}

                { currentModule && currentModule.files[0].fileType  && (<ControllerGalleryPopup model={currentModule} ref={refPopup} setCurrentModule={setCurrentModule}></ControllerGalleryPopup>)}
            
        </React.Fragment>
    )

}

export default ControllerStack


// <div className={`${styles.stackBaseThumb}`}>
// <div className={`${styles.stackBaseHeading}`}>Stack Plan</div>
// <div className={`${styles.stackThumbnails} ${styles.stackThumbnails}` }>

//     {stack && <img src={getContentPath(stack.base)} alt="stack base" />}
// </div>
// </div>